import React from 'react';

const Modal = ({ show, onClose, children, title = null }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className="fixed top-0 bottom-0 left-0 right-0 bg-gray-700 opacity-95 flex justify-center items-center"
      onClick={onClose}
    >
      <div
        className="border border-venturesqueOrange bg-gray-50 py-7 px-10 sm:rounded-xl shadow-sm max-h-screen overflow-y-scroll"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between">
          <h1 className="font-bold text-xl text-gray-500">{title}</h1>
          <button onClick={onClose} className="hover:text-venturesborder-venturesqueOrange">
            <svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        {children}
        <div className={`flex justify-center mt-4 ${title ? null : 'hidden'}`}>
          <button onClick={onClose} className="btn hover:text-venturesborder-venturesqueOrange">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
