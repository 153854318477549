import axios from 'axios';

export const fetchActivities = async (lat, lng, type) => {
  // location = {lat:34.3894, lng: 38.4938}
  try {
    const res = await axios.get(
      `/api/google/activities/location=${lat}%2C%20${lng}&type=${type}`
    );

    return res.data.results;
  } catch (err) {
    console.log(err);
  }
};

export const fetchGooglePhoto = async (photoreference) => {
  try {
    const res = await axios.get(`/api/google/photoref=${photoreference}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
