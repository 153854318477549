import React from 'react';

const Categories = ({ categories }) => {
  const adjustedCategories = Array.isArray(categories)
    ? categories
    : Object.keys(categories);
  if (categories === null) return null;

  let selected = new Set();

  const handleClick = (e) => {
    e.preventDefault();

    const category = e.target.innerText;

    if (!selected.has(category)) {
      selected.add(category);
    } else {
      selected.delete(category);
    }
  };

  const categoryIndex = adjustedCategories.map((category) => (
    <li
      key={category}
      className="btn-venturesqueOrange border-2 border-venturesqueOrange px-2 py-1 rounded-full cursor-pointer"
      onClick={(e) => handleClick(e)}
    >
      {category}
    </li>
  ));

  return <ul className="flex flex-wrap gap-3">{categoryIndex}</ul>;
};

export default Categories;
