import { useState } from "react";
import "./App.css";

// Router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Components
import Nav from "./components/Nav";
import Footer from "./components/Footer";

// Pages
import Home from "./pages/Home";
import Results from "./pages/Results";
import About from "./pages/About";
import Donate from "./pages/Donate";
import Contact from "./pages/Contact";
import Profile from "./pages/Profile";
import Error from "./pages/Error";
import Modals from "./components/Modals";
import { AuthProvider } from "./contexts/AuthContext";

function App() {
  const [showAuthForm, setShowAuthForm] = useState(false);
  const [authType, setAuthType] = useState("");

  const closeAuthForm = () => {
    setShowAuthForm(false);
    setAuthType("");
  };

  const switchAuthForm = (type) => {
    setAuthType(type);
  };

  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Nav
            setShowAuthForm={() => setShowAuthForm(true)}
            closeAuthForm={closeAuthForm}
            setAuthType={setAuthType}
          />
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/search?location=:location&cuisine=:cuisine" element={<Results />}/> */}
            <Route path="/search" element={<Results />}/>
            <Route path="about" element={<About />} />
            <Route path="donate" element={<Donate />} />
            <Route path="contact" element={<Contact />} />
            <Route path="profile/:uid" element={<Profile />} />
            <Route path="error" element={<Error />} />
          </Routes>
          <Modals
            showAuthForm={showAuthForm}
            closeAuthForm={closeAuthForm}
            authType={authType}
            switchAuthForm={switchAuthForm}
          />
          <Footer />
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
