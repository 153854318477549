import React, { useEffect } from 'react';
import Card from './Card';

import { sortBusinesses } from '../util/algorithms/sort';
import { extractCategoriesFromRestaurants } from '../util/algorithms/extract';

const Businesses = ({
  type,
  businesses = [],
  loadMore,
  startIdx,
  setRestaurantCategories,
}) => {
  const sortedBusinesses = sortBusinesses(type, businesses);

  const businessIndex = sortedBusinesses.map((business, i) => (
    <Card
      key={type === 'restaurants' ? business.id : business.place_id}
      index={i}
      type={type}
      business={business}
    />
  ));

  useEffect(() => {
    if (type === 'restaurants') {
      let categories = extractCategoriesFromRestaurants(sortedBusinesses);
      setRestaurantCategories(categories);
    }
  }, []);

  const businessDisplay = loadMore
    ? businessIndex
    : businessIndex.slice(startIdx, startIdx + 3);

  return (
    <div className="mt-6 grid sm:grid-cols-3 gap-6">{businessDisplay}</div>
  );
};

export default Businesses;
