import React from 'react';
import Polaroid from '../components/Polaroid';

import preferences from '../assets/undraw_preferences_popup_re_4qk0.svg';
import invite from '../assets/undraw_following_re_d5aa.svg';
import hangout from '../assets/undraw_having_fun_re_vj4h.svg';

const ThreeGrid = () => {
  return (
    <div className="my-10 flex flex-col items-center">
      <div>
        <h1 className="mb-9 font-bold text-3xl text-venturesqueOrange text-center">
          Setting up a date or hangout has never been easier!
        </h1>
      </div>
      <div className="grid grid-rows-3 sm:grid-rows-1 grid-cols-1 sm:grid-cols-3 gap-10">
        <Polaroid text={'add your preferences'} img={preferences} index={0} />
        <Polaroid text={'invite your friends'} img={invite} index={1} />
        <Polaroid text={'have fun!'} img={hangout} index={2} />
      </div>
    </div>
  );
};

export default ThreeGrid;
