import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router';
import ProfileImage from '../assets/undraw_profile_pic_ic-5-t.svg';

const Profile = () => {
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState();
  const navigate = useNavigate();

  const handleLogout = async () => {
    setError('');

    try {
      await logout();
      navigate('/');
    } catch {
      setError('Unable to log out');
    }
  };

  if (!currentUser) return null;

  return (
    <div className="flex justify-center">
      <div className="flex flex-col justify-center w-full mx-6 md:w-2/5 rounded-lg shadow">
        <h1 className="text-center mt-4 font-bold text-4xl">Profile</h1>
        {error && <span>error</span>}
        <div className="flex flex-col justify-center items-center gap-2 my-3 mx-6">
          <div className="w-40 h-40 border-2 border-venturesqueOrange rounded-full shadow-md mb-3">
            <img src={currentUser.photoURL || ProfileImage} alt="Profile" />
          </div>
          <div>
            <strong className="pr-2">Name:</strong>
            {currentUser.displayName
              ? currentUser.displayName
              : currentUser.email}
          </div>
          <div>
            <strong className="pr-2">Email:</strong>
            {currentUser.email}
          </div>
        </div>
        <div className="flex justify-center gap-3 p-3">
          <button className="border-2 border-venturesqueOrange text-venturesqueOranborder-venturesqueOrange hover:text-white hover:bg-venturesqueOranborder-venturesqueOrange cursor-pointer py-2 px-4 rounded-full">
            Update
          </button>
          <button
            className="border-2 border-venturesqueOrange text-venturesqueOranborder-venturesqueOrange hover:text-white hover:bg-venturesqueOranborder-venturesqueOrange hover cursor-pointer py-2 px-4 rounded-full"
            onClick={handleLogout}
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
