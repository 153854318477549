import React from 'react';

import construction from '../assets/undraw_add_information_j2wg.svg';

const UnderConstruction = () => {
  return (
    <div className="md:grid md:grid-cols-2 m-20 md:w-1/2">
      <div>
        <span className="text-xl font-bold text-venturesqueOrange">
          Under construction and will be available soon...
        </span>
        <span className="block">Thank you for your patience!</span>
      </div>
      <img
        src={construction}
        alt="under construction"
        className="w-full mt-10 md:mt-0"
      />
    </div>
  );
};

export default UnderConstruction;
