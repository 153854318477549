import React from 'react';

import UnderConstruction from '../components/UnderConstruction';

const Donate = () => {
  return (
    <div className="flex justify-center">
      <UnderConstruction />
    </div>
  );
};

export default Donate;
