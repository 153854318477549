import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Nav = ({ setShowAuthForm, closeAuthForm, setAuthType }) => {
  const [menuToggle, setMenuToggle] = useState(true);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [active, setActive] = useState(null);

  const handleClick = (e) => {
    e.preventDefault();

    if (currentUser) return handleLogout();
    setShowAuthForm();
    setAuthType(e.target.innerHTML);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch {
      console.log('Unable to log out');
    }
  };

  return (
    <div>
      <nav className="p-4 text-right md:flex md:justify-between">
        <div className="flex justify-between items-center">
          <h1 className="font-bold uppercase">
            <a href="/" className="hover:text-gray-700">
              Venturesque
            </a>
          </h1>
          <div
            className="cursor-pointer md:hidden"
            onClick={() => setMenuToggle(!menuToggle)}
          >
            <svg
              className="w-6 h-6 "
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        <ul
          className={`text-sm mt-3 ${
            menuToggle ? 'hidden' : ''
          } block md:flex md:justify-end`}
        >
          <li
            className={`nav-link ${active === 'home' && 'active'}`}
            onClick={() => setActive('home')}
          >
            <Link to="/">
              <span>Home</span>
            </Link>
          </li>
          <li
            className={`nav-link ${active === 'about' && 'active'}`}
            onClick={() => setActive('about')}
          >
            <Link to="/about">
              <span>About</span>
            </Link>
          </li>
          <li
            className={`nav-link ${active === 'donate' && 'active'}`}
            onClick={() => setActive('donate')}
          >
            <Link to="/donate">
              <span>Donate</span>
            </Link>
          </li>
          <li
            className={`nav-link ${active === 'contact' && 'active'}`}
            onClick={() => setActive('contact')}
          >
            <Link to="/contact">
              <span>Contact</span>
            </Link>
          </li>
          {currentUser && (
            <li
              className={`nav-link ${active === 'profile' && 'active'}`}
              onClick={() => setActive('profile')}
            >
              <Link to={`/profile/${currentUser.uid}`}>
                <span>Profile</span>
              </Link>
            </li>
          )}
          {!currentUser && (
            <li className="nav-link">
              <span
                className="btn btn-red md:border-2 md:border-venturesqueOrange"
                onClick={handleClick}
              >
                Log in
              </span>
            </li>
          )}
          <li className="nav-link">
            <span
              className="btn btn-red md:border-2 md:border-venturesqueOrange"
              onClick={handleClick}
            >
              {currentUser ? 'Logout' : 'Sign up'}
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Nav;
