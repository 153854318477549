import React, { useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";

const Search = ({ locationStart = "", cuisineStart = "" }) => {
  const [location, setLocation] = useState(locationStart);
  const [cuisine, setCuisine] = useState(cuisineStart);
  const [autocompleteInstance, setAutocompelteInstance] = useState(null);
  let navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // navigate(`/donate`);

    // navigate(
    //   `/search/location=${location}&cuisine=${cuisine ? cuisine : "restaurant"}`
    // );
    // navigate({
    //   pathname: "/search",
    //   search: `?location=${location}`,
    // });
    navigate({
      pathname: "/search",
      search: createSearchParams({
        location,
        cuisine: `${cuisine ? cuisine : 'restaurant'}`
      }).toString(),
    });
  };

  const [libraries] = useState(["places"]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const onLoad = (mapInstance) => {
    setAutocompelteInstance(mapInstance);
  };

  // ? Where does this loading occur
  if (!isLoaded) return <div></div>;

  return (
    <div className="transform hover:scale-105 transition ease-out duration-300 mt-2 mb-2 relative">
      <form
        className="flex items-center px-4 py-2 border-2 border-venturesqueOrange rounded-full shadow-lg relative"
        onSubmit={handleSubmit}
      >
        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={() => {
            !autocompleteInstance.getPlace().formatted_address
              ? setLocation(
                  autocompleteInstance.gm_accessors_.place.Oj.predictions[0].ut
                )
              : setLocation(autocompleteInstance.getPlace().formatted_address);
          }}
        >
          <input
            placeholder="city, state"
            className="w-28 outline-none focus:ring-0"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </Autocomplete>
        <span className="text-gray-500 pr-2">/</span>
        <input
          placeholder="cuisine"
          className="w-28 outline-none focus:ring-0"
          value={cuisine}
          onChange={(e) => setCuisine(e.target.value)}
        />
        <button type="submit" className="ml-2">
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </form>
    </div>
  );
};

export default Search;
