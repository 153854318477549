export const extractCategoriesFromRestaurants = (businesses) => {
  let categoriesPerBusiness = businesses.map((business) => business.categories);

  let uniqueCategories = [];
  categoriesPerBusiness.forEach((categories) => {
    categories.forEach((category) => {
      if (!uniqueCategories.includes(category.title))
        uniqueCategories.push(category.title);
    });
  });

  return uniqueCategories;
};
