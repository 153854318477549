import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { fetchRestaurants } from '../hooks/api/yelp/api';
import { fetchActivities } from '../hooks/api/google/api';
// import { useBusinessSearch } from '../hooks/api/yelp/useBusinessSearch';

import Businesses from '../components/Businesses';
import FilterOptions from '../components/FilterOptions';
import Modal from '../components/Modal';
import Dropdown from '../components/Dropdown';
import Search from '../components/Search';
import loadingIcon from '../assets/Infinity-1.1s-200px.gif';
import { activityTypes } from '../seed/activityTypes';

const Results = () => {
  // const [cuisine] = useState('restaurant');
  const [searchParams] = useSearchParams();
  console.log(searchParams);
  let location = searchParams.get("location");
  let cuisine = searchParams.get("cuisine");
  const [type, setType] = useState('tourist_attraction');
  const [restaurants, setRestaurants] = useState();
  const [activities, setActivities] = useState();
  // TODO: refactor code
  // const [businesses, amountResults, searchParams, setSearchParams] =
  //   useBusinessSearch(cuisine, location);
  // const [loading, setLoading] = useState(false);
  const [loadMoreToggle, setLoadMoreToggle] = useState(false);
  const [startIdx, setStartIdx] = useState(0);
  const [showFilters, setShowFilters] = useState(false);

  console.log("results page");

  useEffect(() => {
    debugger
    
    // get restaurants
    fetchRestaurants(location, cuisine).then((res) => {
      setRestaurants(res);

      const latitude = res[0].coordinates.latitude;
      const longitude = res[0].coordinates.longitude;

      // get activities
      fetchActivities(latitude, longitude, type).then((res) => {
        setActivities(res);
      });
    });
    // eslint-disable-next-line
  }, [location, cuisine, type]);

  const [restaurantCategories, setRestaurantCategories] = useState();

  // if (!restaurants || !activities)
  //   return (
  //     <div className="flex justify-center">
  //       <img src={loadingIcon} alt="Loading..." />
  //     </div>
  //   );

  return (
    <main className="flex flex-col justify-center items-center mx-6 py-4">
      <header className="flex flex-col justify-center items-center">
        <h2 className="title-semibold text-xl">Your next date in</h2>
        <Search locationStart={location} cuisineStart={cuisine} />
        <span className="block text-venturesqueOrange text-xl lowercase">
          {
            <Dropdown
              options={activityTypes}
              defaultOption={type}
              setType={setType}
            />
          }
        </span>
      </header>

      <div className="lg:w-8/12">
        <div className="flex justify-start gap-4 mt-2">
          <button
            onClick={() => setShowFilters(true)}
            className="btn bg-gray-200 text-gray-400 px-4 pb-2 hover:shadow-inner transform hover:scale-125 hover:opacity-50 transition ease-out duration-300"
          >
            Filters
          </button>
        </div>
        <h4 className="font-bold mt-6 pb-2 border-b border-gray-200">
          Restaurants
        </h4>
        <Businesses
          type={'restaurants'}
          businesses={restaurants}
          loadMore={loadMoreToggle}
          startIdx={startIdx}
          setRestaurantCategories={setRestaurantCategories}
        />

        <h4 className="font-bold mt-6 pb-2 border-b border-gray-200">
          Activities
        </h4>
        <Businesses
          type={'activities'}
          businesses={activities}
          loadMore={loadMoreToggle}
          startIdx={startIdx}
        />

        <div className="flex justify-center gap-4 mt-6">
          {startIdx === 0 ? null : (
            <button
              onClick={() => setStartIdx(startIdx - 3)}
              className="btn bg-gray-200 text-gray-400 px-4 py-2 hover:shadow-inner transform hover:scale-125 hover:opacity-50 transition ease-out duration-300"
            >
              Back
            </button>
          )}
          <button
            onClick={() => setLoadMoreToggle(!loadMoreToggle)}
            className="btn bg-gray-200 text-gray-400 px-4 py-2 hover:shadow-inner transform hover:scale-125 hover:opacity-50 transition ease-out duration-300"
          >
            Show all
          </button>
          <button
            onClick={() => setStartIdx(startIdx + 3)}
            className="btn bg-gray-200 text-gray-400 px-4 py-2 hover:shadow-inner transform hover:scale-125 hover:opacity-50 transition ease-out duration-300"
          >
            Next
          </button>
        </div>

        <Modal
          title={'Filters'}
          show={showFilters}
          onClose={() => setShowFilters(false)}
        >
          <FilterOptions categories={restaurantCategories} />
        </Modal>
      </div>
    </main>
  );
};

export default Results;
