export const activityTypes = {
  'Amusement Park': 'amusement_park',
  'Aquarium':'aquarium',
  'Art Gallery':'art_gallery',
  'Bakery':'bakery',
  'Bar':'bar',
  'Cycling':'bicycle_store',
  'Reading':'book_store',
  'Bowling':'bowling_alley',
  'Cafe':'cafe',
  'Camping':'campground',
  'Casino':'casino',
  'Clothes':'clothing_store',
  'Department Stores':'department_store',
  'Electronics':'electronics_store',
  'Home Goods':'home_goods_store',
  'Jewelrey':'jewelry_store',
  'Library':'library',
  'Movies':'movie_theater',
  'Museums':'museum',
  'Night Life':'night_club',
  'Painting':'painter',
  'Parks':'park',
  'Restaurants':'restaurant',
  'RV Park':'rv_park',
  'Shoes':'shoe_store',
  'Shopping':'shopping_mall',
  'Spa':'spa',
  'Sports':'stadium',
  'Store':'store',
  'Tourist Attractions':'tourist_attraction',
  'Universities':'university',
  'Zoo':'zoo',
};
