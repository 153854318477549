import React from 'react';

const Polaroid = ({ text = null, img, index = Math.round(Math.random()) }) => {
  const rotation =
    Math.ceil(Math.random() * 25) + 10 * (index % 2 === 0 ? 1 : -1);

  return (
    <div
      className={`w-60 h-80 rounded-lg drop-shadow-lg border-2 border-white bg-white`}
      style={{ transform: `rotate(${rotation}deg)` }}
    >
      <div className="h-60 p-4 m-4 shadow-inner shadow-gray-400 overflow-hidden flex justify-center items-center">
        <img className="object-contain" src={img} alt="testing" />
      </div>
      <div className="flex justify-center">
        <span className="mx-4 font-grapenut text-xl">{text}</span>
      </div>
    </div>
  );
};

export default Polaroid;
