import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const AuthForm = ({ authType, switchAuthForm, closeAuthForm }) => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup, login, currentUser } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      authType === 'Sign up' &&
      passwordRef.current.value !== passwordConfirmRef.current.value
    ) {
      return setError('Passwords do not match');
    }

    try {
      setError('');
      setLoading(true);
      if (authType === 'Log in') {
        await login(emailRef.current.value, passwordRef.current.value);
      } else {
        await signup(emailRef.current.value, passwordRef.current.value);
      }
      closeAuthForm();
      navigate('/');
    } catch {
      const errorMessage =
        authType === 'Log in'
          ? 'Failed to sign in'
          : 'Failed to create an account';
      setError(errorMessage);
    }

    setLoading(false);
  };

  const authTitle = authType === 'Log in' ? 'Log In' : 'Sign Up';
  const otherType = authType === 'Log in' ? 'Sign up' : 'Log in';

  return (
    <div>
      <div className="flex flex-col justify-center items-center">
        <h2 className="py-2 text-4xl text-black font-bold">{authTitle}</h2>
        {error && <span className="text-red-700 pt-4">{error}</span>}
        <form className="my-3 pb-3" onSubmit={handleSubmit}>
          <div>
            <label className="auth-label">Email</label>
            <input
              className="auth-input"
              type="email"
              ref={emailRef}
              required
            />
          </div>
          <div>
            <label className="auth-label">Password</label>
            <input
              className="auth-input"
              type="password"
              ref={passwordRef}
              required
            />
          </div>
          {authType === 'Log in' ? null : (
            <div>
              <label className="auth-label">Password Confirmation</label>
              <input
                className="auth-input"
                type="password"
                ref={passwordConfirmRef}
                required
              />
            </div>
          )}
          <button
            className="w-96 bg-venturesqueOrange mt-3 p-3 text-white rounded-md font-bold hover:bg-red-500"
            type="submit"
            disabled={loading}
          >
            {authTitle}
          </button>
        </form>
      </div>
      <div className="flex justify-center">
        {authType === 'Log in'
          ? 'Already have an account?'
          : 'Need an account?'}
        <span
          className="font-bold text-venturesqueOrange pl-2 hover:text-red-500 cursor-pointer"
          onClick={() => switchAuthForm(otherType)}
        >
          {authType === 'Log in' ? 'Sign Up' : 'Log In'}
        </span>
      </div>
    </div>
  );
};

export default AuthForm;
