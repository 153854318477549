import React from 'react';

const Error = () => {

  console.log("error page");

  return (
    <div className="px-4">
      <span className="block text-3xl text-gray-400 uppercase">The page does not exist...</span>
      <span className="block text-xl text-gray-300">Please try a new search</span>
    </div>
  );
};

export default Error;
