import React from 'react';

import Categories from './Categories';
import { activityTypes } from '../seed/activityTypes';

const FilterOptions = ({ categories }) => {
  return (
    <div className="flex flex-col justify-center min-w-screen-sm md:max-w-screen-md ">
      <h4 className="font-bold mt-4 mb-4 pb-1 border-b border-gray-200">
        What cuisine are you in the mood for?
      </h4>
      <Categories categories={categories} />

      <h4 className="font-bold mt-6 mb-4 pb-1 border-b border-gray-200">
        What do you like to do?
      </h4>
      <Categories categories={activityTypes} />
    </div>
  );
};

export default FilterOptions;
