import React, { useState } from 'react';

const Dropdown = ({ options, defaultOption = null, setType }) => {
  const [option, setOption] = useState(defaultOption);

  function handleSubmit(e) {
    e.preventDefault();
  }

  function handleChange(e) {
    setOption(e.target.value);
    setType(e.target.value);
  }

  const optionList = Object.keys(options).map((key) => (
    <option key={key + options[key]} value={options[key]}>
      {key}
    </option>
  ));

  return (
    <div className="w-3/4">
      <form onSubmit={handleSubmit}>
        <div className="rounded-full">
          <select
            className="outline-none text-venturesqueOrange lowercase text-xl font-bold"
            value={option}
            onChange={handleChange}
          >
            {optionList}
          </select>
        </div>
      </form>
    </div>
  );
};

export default Dropdown;
