export const sortBusinesses = (type, businesses) => {
  const filteredBusinesses = businesses.filter((business) => {
    const filterConditions =
      type === 'restaurants'
        ? business.review_count >= 75 &&
          (business.rating >= 3.5 || business.ratings !== 5)
        : business.user_ratings_total > 75 &&
          (business.rating >= 3.5 || business.ratings !== 5) &&
          business.photos;

    return filterConditions;
  });

  const sortedBusinesses = filteredBusinesses
    .sort((a, b) => b.review_count - a.review_count)
    .sort((a, b) => b.rating - a.rating);

  return sortedBusinesses;
};

