import React from 'react';
import { Link } from 'react-router-dom';

import Search from '../components/Search';
import Card1 from '../components/Card1';
import ThreeGrid from '../layouts/ThreeGrid';

import {
  popularDestinationIndex,
  internationalDestinationIndex,
} from '../seed/destinations';

const Home = () => {
  const popularDestinations = popularDestinationIndex.map(
    ({ imgSrc, title }) => (
      <Link
        key={imgSrc + title}
        to={{
          pathname: "/search",
          search: `?location=${title}&cuisine=restaurant`,
        }}
      >
        <Card1 imgSrc={imgSrc} title={title} />
      </Link>
    )
  );

  const internationalDestinations = internationalDestinationIndex.map(
    ({ imgSrc, title }) => (
      <Link
        key={imgSrc + title}
        to={{
          pathname: '/search',
          search: `?location=${title}&cuisine=restaurant`
        }}
      >
        <Card1 imgSrc={imgSrc} title={title} />
      </Link>
    )
  );

  return (
    <div className="flex flex-col justify-center items-center mt-5 overflow-hidden">
      <h1>Where is your next adventure?</h1>
      <Search />
      <div className="lg:w-8/12 mx-10 mb-4">
        <ThreeGrid />
        <h4 className="font-bold mt-8 pb-2 border-b border-gray-200">
          Popular Destinations
        </h4>
        <div className="mt-6 grid sm:grid-cols-3 gap-6">
          {popularDestinations}
        </div>
        <h4 className="font-bold mt-8 pb-2 border-b border-gray-200">
          International Travel
        </h4>
        <div className="mt-6 grid sm:grid-cols-3 gap-6">
          {internationalDestinations}
        </div>
      </div>
    </div>
  );
};

export default Home;
