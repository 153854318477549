import React, { useState, useEffect } from 'react';

import { fetchGooglePhoto } from '../hooks/api/google/api';

const Card = ({ index = '', type = 'restaurants', business = {} }) => {
  const [photoURL, setPhotoURL] = useState(null);

  useEffect(() => {
    if (type === 'activities' && business.photos) {
      fetchGooglePhoto(business.photos[0].photo_reference).then((res) => {
        setPhotoURL(res);
      });
    }
    // eslint-disable-next-line
  }, null);

  const badge =
    index === 0 ? (
      <div className="badge">
        <span>Recommended</span>
      </div>
    ) : null;

  // shorten restaurant/activity name
  const shortenedName =
    business.name.length > 20
      ? business.name.slice(0, 20) + '...'
      : business.name;

  // refactor to obtain image for google for activities
  const imgSource = type === 'restaurants' ? business.image_url : photoURL;

  const reviewCount =
    type === 'restaurants'
      ? business.review_count
      : business.user_ratings_total;

  // [address, city, state, country]
  const address =
    type === 'restaurants' ? null : business.plus_code.compound_code.split(', ');

  const city = type === 'restaurants' ? business.location.city : address[0].split(' ')[1];
  const state = type === 'restaurants' ? business.location.state : address[1];

  console.log(business.name);
  console.log(business);

  return (
    <div className="card w-full">
      <a href={business.url}>
        <img src={imgSource} alt="" className="w-full h-48 object-cover" />
        <div className="m-4 absolute top-28 mt-7">
          <span
            className="font-bold text-white"
            // refactor code: need to find another way to make the words on the card more visible
            // style={{
            //   '-webkit-text-stroke-width': '1px',
            //   '-webkit-text-stroke-color': 'rgb(224,224,224)',
            // }}
          >
            {`${shortenedName} / ${business.rating} / ${reviewCount}`}
          </span>
          <span className="block text-white text-sm">
            {`${city}, ${state}`}
          </span>
        </div>
      </a>
      {badge}
    </div>
  );
};

export default Card;
