import React from 'react';

const Footer = () => {
  return (
    <footer className="flex flex-col justify-center items-center my-4">
      <span className="text-xs">
        Copyright © 2022 Venturesque Inc. All rights reserved.
      </span>
    </footer>
  );
};

export default Footer;
