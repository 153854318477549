import React from 'react';
import {
  FaUserTie,
  FaUserAstronaut,
  FaWineGlassAlt,
  FaUsers,
  FaRegGem,
} from 'react-icons/fa';

const About = () => {
  return (
    <div className="flex flex-col justify-center items-center px-20 py-4">
      <section className="py-4">
        <h1 className="title-semibold text-center">Our Vision</h1>
        <p className="mt-6 px-30 text-center max-w-screen-sm">
          To simplify, expedite, and find the adventures you and your loved ones want to go on.
        </p>
      </section>
      <section className="py-4">
        <h1 className="title-semibold mb-4 text-center">Our Principles</h1>
        <div className="sm:grid grid-cols-3 gap-10">
          <div className="team-card">
            <FaUsers size={70} className="text-venturesqueOrange" />
            <span className="pt-3">Cherish relationships</span>
          </div>
          <div className="team-card">
            <FaWineGlassAlt size={70} className="text-venturesqueOrange" />
            <span className="pt-3">Celebrate the Small Wins</span>
          </div>
          <div className="team-card">
            <FaRegGem size={70} className="text-venturesqueOrange" />
            <span className="pt-3">Strive for the best</span>
          </div>
        </div>
      </section>
      <section className="pt-4">
        <h1 className="title-semibold mb-6 text-center">Our Team</h1>
        <div className="sm:grid grid-cols-3 gap-10">
          <div className="team-card">
            <FaUserTie size={70} className="text-venturesqueOrange" />
            <span className="team-header">James P.</span>
            <span className="team-title">CEO & Founder</span>
          </div>
          <div className="team-card">
            <FaUserAstronaut size={70} className="text-venturesqueOrange" />
            <span className="team-header">Vivian C.</span>
            <span className="team-title">Chief Design Officer</span>
          </div>
          <div className="team-card">
            <FaUserAstronaut size={70} className="text-venturesqueOrange" />
            <span className="team-header">Jung P.</span>
            <span className="team-title">Chief Technology Officer</span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
