import React from 'react';
import Modal from './Modal';
import AuthForm from './AuthForm';

const Modals = ({ showAuthForm, closeAuthForm, authType, switchAuthForm }) => {
  return (
    <div className="z-50">
      <Modal show={showAuthForm} onClose={closeAuthForm}>
        <AuthForm authType={authType} switchAuthForm={switchAuthForm} closeAuthForm={closeAuthForm} />
      </Modal>
    </div>
  );
};

export default Modals;
