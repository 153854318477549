export const popularDestinationIndex = [
  {
    imgSrc:
      'https://blog-www.pods.com/wp-content/uploads/2019/04/MG_1_1_New_York_City-1.jpg',
    title: 'New York City',
  },
  {
    imgSrc:
      'https://www.omnihotels.com/-/media/digex/destination-city/city-los-angeles-2880x1870.jpg?la=en&h=1286&w=1980&mw=1980&hash=E58D1ACB5F8EE53CD0AAF51B7DC66A981CD9E909',
    title: 'Los Angeles',
  },
  {
    imgSrc:
      'https://content.r9cdn.net/rimg/dimg/17/74/0ca6e469-city-30651-1632b88f203.jpg?width=1750&height=1000&xhint=2635&yhint=1507&crop=true',
    title: 'Miami',
  },
  {
    imgSrc:
      'https://assets3.thrillist.com/v1/image/3048951/1200x630/flatten;crop_down;webp=auto;jpeg_quality=70',
    title: 'Colorado',
  },
  {
    imgSrc:
      'http://cdn.cnn.com/cnnnext/dam/assets/201230100452-10-2021-travel-destinations-hawaii.jpg',
    title: 'Kauai',
  },
  {
    imgSrc:
      'https://www.thestreet.com/.image/t_share/MTg3MjQ4OTU3ODI5Njg3MTgy/las-vegas.jpg',
    title: 'Las Vegas',
  },
  // {
  //   imgSrc:
  //     'https://visitdallas.imgix.net/Hotels/Drone_Skyline_Shot_2018_LoRes.jpg?fit=crop&crop=focalpoint&fp-x=0.55&fp-y=0.50&10&auto=format&q=60&fp-z=1.5',
  //   title: 'Dallas',
  // },
  // {
  //   imgSrc:
  //     'https://cw-gbl-gws-prod.azureedge.net/-/media/cw/americas/united-states/office-pages/hawaii/honolulu-mobile.jpg?rev=4d662cc148cd4b4196bff47fe2bde814',
  //   title: 'Honolulu',
  // },
  // {
  //   imgSrc:
  //     'https://www.telegraph.co.uk/content/dam/Travel/Destinations/North%20America/USA/Boston/boston-travel-guide-lead-image-xlarge.jpg',
  //   title: 'Boston',
  // },
];

export const internationalDestinationIndex = [
  // {
  //   imgSrc:
  //     'https://www.state.gov/wp-content/uploads/2019/04/South-Korea-2109x1406.jpg',
  //   title: 'Busan',
  // },
  {
    imgSrc:
      'https://cdn.getyourguide.com/img/location/5b45f52dbd137.jpeg/88.jpg',
    title: 'Cancun',
  },
  // {
  //   imgSrc:
  //     'https://exp.cdn-hotels.com/hotels/1000000/490000/487100/487018/67e08afd_z.jpg?impolicy=fcrop&w=773&h=530&q=high',
  //   title: 'Punta Cana',
  // },
  // {
  //   imgSrc:
  //     'https://elitetraveler.com/wp-content/uploads/2020/07/DJI_0166-2-730x450.jpg',
  //   title: 'Los Cabos',
  // },
  // {
  //   imgSrc:
  //     'https://cdn.thecrazytourist.com/wp-content/uploads/2018/07/ccimage-shutterstock_287861729.jpg',
  //   title: 'Quebec City',
  // },
  {
    imgSrc:
      'https://media.cntraveler.com/photos/5cf96a9dd9fb41f17ed08435/master/pass/Eiffel%20Tower_GettyImages-1005348968.jpg',
    title: 'Paris',
  },
  // {
  //   imgSrc:
  //     'https://www.planetware.com/wpimages/2019/09/puerto-rico-best-beach-resorts-el-san-juan-hotel.jpg',
  //   title: 'San Juan',
  // },
  {
    imgSrc:
      'https://media.cntraveler.com/photos/5a903dd660543c4ae96c2e74/16:9/w_2560%2Cc_limit/GettyImages-497268617.jpg',
    title: 'Rome',
  },
];
