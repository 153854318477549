import React from 'react';

const Card1 = ({ imgSrc, title }) => {
  return (
    <div className="card w-full">
      <img src={imgSrc} alt="" className="w-full h-48 object-cover" />
      <div className="m-4 absolute top-32 mt-7">
        <span
          className="font-bold text-white"
          // TODO refactor code: need to find another way to make the words on the card more visible
          // style={{
          //   '-webkit-text-stroke-width': '1px',
          //   '-webkit-text-stroke-color': 'rgb(224,224,224)',
          // }}
        >
          {title}
        </span>
      </div>
    </div>
  );
};

export default Card1;
